<template>
  <v-container
    fluid
  >
    <v-row class="justify-center">
      <v-card
        class="mb-0 py-0 mx-6"
        width="100%"
      >
        <v-card-title
          class="text-h3 font-weight-bold grey--text mt-2 pb-0 ml-4"
        >
          Add Equipment Wizard
        </v-card-title>
        <ewc-equipment-flow
          :local-bus="localBus"
          @update-pending-requests-count="count => pendingRequestsCount = count"
        />
      </v-card>
      <v-col
        cols="12"
        class="d-flex justify-center"
      >
        <v-alert
          v-if="showAlert"
          width="950px"
          type="error"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <material-snackbar
      v-model="showSnackBar"
      type="info"
      timeout="3000"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import ewcHolder from '@/util/ewc-holder'

  export default {
    name: 'EWCAddEquipment',
    data: () => ({
      localBus: new Vue(),

      showSnackBar: false,
      snackBarMessage: '',
      showAlert: false,
      alertMessage: '',
      pendingRequestsCount: 0,

    }),
    created () {
      if (ewcHolder.getFloorplan()) this.pendingRequestsCount = ewcHolder.getFloorplan().pendingEquipmentEwc.length

      this.localBus.$on('showAlertMessage', message => {
        this.showAlert = true
        this.alertMessage = message
      })
      this.localBus.$on('removeAlertMessage', () => {
        this.showAlert = false
      })
      this.localBus.$on('addSuccess', message => {
        this.showSnackBar = true
        this.snackBarMessage = message
      })
    },
    methods: {
    },
  }
</script>
<style>
.wizard-equipment {
  width: 400px;
  margin-left: 48px;
}
.wizard-model {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
}
</style>
